body {
    font-family: 'Open Sans', sans-serif;
    background: #eff2f5;
}

h1, h2, h3 {
    font-weight: 600;
}

.ant-layout{
    max-width: 60%;
    margin: auto;
    height: 100vh;
}

.ant-modal-header {
    border: none !important;
    padding-bottom: 0;
}
.ant-modal-title {
    font-size: 20px;
}

.ant-message {
    top: initial;
    bottom: 0;
}

.ant-message-notice {
    text-align: right;
}

.ant-message-notice-content {
    background: rgba(255,255,255,.9) !important;
}

.ant-message .anticon {
    display: none;
}

.ant-comment-actions {
    margin-top: 2px !important;
}
.ant-comment-content-detail {
    font-size: 13px;
}
.comment-action {
    padding-left: 4px;
    cursor: 'auto';
}

.ant-comment-inner {
    padding: 0 !important;
}

.ant-form-item-explain {
    font-size: 12px;
    line-height: 1.2;
    min-height: initial;
}

.ant-input-textarea {
    position: relative;
}
.ant-form-item .ant-input-textarea-show-count::after {
    margin: 0;
    position: absolute;
    top: 0px;
    right: 2px;
    font-size: 8px;
    }

.ant-collapse-arrow {
    position: absolute;
}

.ant-collapse-ghost {
    background-color: #eff2f5 !important;
}

.ant-layout-sider {
    transition: all .3s ease-in-out !important;
}